<template>
  <section class="build-to-rent">
    <section class="hero">
      <div class="hero-container">
        <h1>Faire construire une maison pour la louer : quels avantages ?</h1>
      </div>
    </section>
    <div class="hero-img">
      <img src="@/assets/img/pages/build-to-rent-1.jpg" alt="Construire une maison pour la louer" />
    </div>
    <article class="grid-container article">
      <div class="article-title">
        <h3>
          L’investissement locatif ne se limite pas aux appartements neufs de centre-ville. Des
          particuliers, de plus en plus nombreux, se tournent vers l’habitat individuel, bâti en
          zone rurale ou périurbaine, pour réaliser leur opération. Un choix stratégique qui ne
          repose pas uniquement sur des motivations fiscales.
        </h3>
      </div>
      <div class="article-card">
        <p>
          Maison ou appartement : lequel de ces deux biens ofre le plus d’atouts pour garantir la
          réussite d’un investissement locatif ?
        </p>
        <p>
          La plupart des particuliers qui s’engagent dans ce type d’opération visent prioritairement
          l’immobilier collectif, érigé en secteurs tendus (surtout dans les centre-ville des
          métropoles où la demande étudiante est forte) an de bénécier d’avantages scaux prévus par
          le dispositif Pinel.
        </p>
        <p>
          Ces réductions d’impôt, accordées par paliers en fonction de la durée de mise en location
          du logement (12% pour six ans, 18% pour neuf et jusqu’à 21% pour douze) s’appliquent
          également aux maisons neuves, bâties dans le périmètre des grandes agglomérations, à
          mi-chemin parfois entre zones urbaine et rurale (bassin nantais par exemple, mais aussi
          une dizaine de communes autour de Saint-Nazaire. et une trentaine dans le rayon d’Angers).
        </p>
      </div>
      <div class="article-rental-vacancy">
        <h2>Un risque minimal de vacance locative en maison</h2>
        <div class="article-rental-vacancy-img">
          <img src="@/assets/img/pages/build-to-rent-2.jpg" alt="Maison individuel avec terrasse" />
          <img src="@/assets/img/pages/build-to-rent-3.jpg" alt="Maison individuel avec garage" />
        </div>
        <div class="article-rental-vacancy-text">
          <p>
            Pour autant, le choix de ceux qui investissent dans un habitat individuel n’est pas
            seulement dicté par des considérations d’ordre fiscal. Cette catégorie de biens,
            minoritaire dans le parc locatif (20%, contre 80% pour les appartements) évolue en effet
            sur marché à fort potentiel : une étude réalisée en 2017 par un grand groupe immobilier
            révélait que pour plus de 70% des français, la maison reste le logement idéal, quitte à
            devoir en louer une s’ils n’ont pas les moyens –ou l’ambition – de devenir propriétaire
            : un pavillon situé dans une commune bien desservie en transports, écoles et services
            publics, et distante d’une demi-heure au plus d’un gros centre urbain, se louera sans
            difficulté et souvent pour une longue période.
          </p>

          <p>
            Selon l’indice PAP 2018, les occupants d’une maison locative, souvent des familles,
            s’approprient plus facilement les lieux qu’un appartement, l’entretiennent davantage et,
            surtout, y restent plus longtemps (80 mois moyenne, contre 30 pour un studio). Un gage
            de sécurité supplémentaire pour le bailleur qui peut, de surcroît, pratiquer des loyers
            plus élevés en raison de surfaces habitables supérieures à celles des ensembles
            collectifs (selon SeLoger.com , le loyer moyen d’une maison non meublée en France
            s’établit 901 euros, contre 670 euros pour un appartement*.
          </p>

          <p>
            Autres avantages qui plaident en faveur d’une construction : les frais de notaire qui
            s’appliquent uniquement au terrain à bâtir sont très réduits par rapport aux montants
            facturés dans le cadre d’une transaction dans l’immobilier ancien. Le propriétaire d’une
            maison neuve est également exonéré de taxe foncière pendant deux ans et n’a pas à régler
            de charges copropriétés, contrairement à son homologue qui a acquis un logement dans un
            immeuble.
          </p>

          <p>
            *A l’inverse, la moyenne des loyers rapportés à la surface est plus chère en
            appartement, soit 15,3€/m² contre 11,9€ lorsque le logement est vide. Rappelons qu’un
            bien éligible au dispositif Pinel doit, en contrepartie des défiscalisations, appliquer
            des loyers plafonnés.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'build-to-rent',
};
</script>

<style lang="sass">
.build-to-rent
  background-color: $medium-bg
  padding-bottom: 6rem

  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center

    &-container
      margin-top: 8rem
      width: 60rem

      h1
        color: $white
        text-align: center

    &-img
      display: flex
      flex-direction: row
      justify-content: center

      img
        border-radius: $global-border-radius
        margin-top: -15rem

  .article
    width: 90%
    max-width: 950px

    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto

      h3
        text-align: center

    &-card
      background-color: $white
      border-radius: $global-border-radius
      box-shadow: $drop-shadow
      padding: 2rem 2.5rem
      margin: 4.5rem auto 0 auto

      p + p
        margin-top: 1rem

    &-rental-vacancy
      margin: 4.5rem auto 0 auto

      h2
        text-align: center
        color: $primary

      &-img
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: 1fr
        grid-column-gap: 1rem
        margin-top: 1rem

        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius

      &-text
        margin-top: 3rem

        p + p
          margin-top: 1rem
  @media (max-width: 768px)
    .hero
      height: 25rem
      &-container
        width: 90%

      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
</style>

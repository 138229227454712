import { render, staticRenderFns } from "./BuildToRent.vue?vue&type=template&id=ea1d2952&"
import script from "./BuildToRent.vue?vue&type=script&lang=js&"
export * from "./BuildToRent.vue?vue&type=script&lang=js&"
import style0 from "./BuildToRent.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports